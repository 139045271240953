<template>
  <div class="field-file">
    <input
      ref="fileInput"
      class="field-file__input"
      type="file"
      :accept="allowedFormats"
      hidden
      @change="handleFileChange"
    />
    <!-- Отображение кнопки или выбранного файла с крестиком -->
    <div v-if="modelValue" class="field-file__value">
      {{ modelValue.name }}
      <button class="field-file__clear" type="button" @click.prevent="clearFile">
        <NuxtIcon name="IconCancel" filled />
      </button>
    </div>
    <button v-else class="field-file__trigger" type="button" @click.prevent="triggerFileInput">
      <NuxtIcon name="IconAttach" filled />{{ placeholder }}
    </button>
    <div v-if="errorMessage" class="field-file__error">{{ errorMessage }}</div>
    <div class="field-file__desc">
      <div>Максимальный размер файла 5 МБ</div>
      <div>
        Разрешенные форматы:
        {{ type === 'django.forms.ImageField' ? '.png, jpg' : '.docx, .xlsx, .pdf' }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import './styles.scss';

// Пропсы и эмиты
const props = defineProps<{
  maxFileSize: number; // максимальный размер файла в байтах
  allowedFormats: string[]; // разрешенные форматы файлов, например ['image/jpeg', 'image/png']
  modelValue: File | null; // текущий выбранный файл для v-model
  placeholder: string;
  type?: 'django.forms.FileField' | 'django.forms.ImageField';
}>();

const emits = defineEmits<{
  (event: 'update:modelValue', file: File | null): void;
}>();

const fileInput = ref<HTMLInputElement | null>(null);
const errorMessage = ref<string | null>(null);

// Триггер для input[type="file"]
const triggerFileInput = () => {
  fileInput.value?.click();
};

// Обработчик изменения файла
const handleFileChange = (event: Event) => {
  const files = (event.target as HTMLInputElement).files;
  if (!files || files.length === 0) {
    return;
  }

  const file = files[0];
  validateAndSetFile(file);
};

// Функция для очистки файла
const clearFile = () => {
  emits('update:modelValue', null); // Обновляем v-model в родительском компоненте
  if (fileInput.value) {
    fileInput.value.value = ''; // Очищаем input file
  }
  errorMessage.value = null;
};

// Функция для валидации и установки файла
const validateAndSetFile = (file: File) => {
  console.log(file);
  // Проверка размера файла
  if (file.size > props.maxFileSize) {
    errorMessage.value = `Размер файла превышает максимально допустимый размер ${props.maxFileSize} байт.`;
    return;
  }

  // Проверка формата файла
  if (!props.allowedFormats.includes(file.type)) {
    errorMessage.value = `Формат файла не поддерживается. Допустимые форматы: ${props.allowedFormats.join(
      ', ',
    )}.`;
    return;
  }

  // Обновление модели и сброс сообщения об ошибке
  errorMessage.value = null;
  emits('update:modelValue', file); // Обновляем значение v-model в родительском компоненте
};
</script>
